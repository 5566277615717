"use client";
import { ContactDialog } from "@/components/home/contact-dialog";
import { FeatureGrid } from "@/components/home/feature-grid";
import { PrivacyPolicyDialog } from "@/components/home/privacy-policy-dialog";
import { TermsOfServiceDialog } from "@/components/home/terms-of-service";
import { PlaceholdersAndVanishInput } from "@/components/ui/placeholders-and-vanish-input";
import { PwaInstallPrompt } from "@/components/ui/pwa-install-prompt";
import { TextHoverEffect } from "@/components/ui/text-hover-effect";
import { usePWA } from "@/hooks/use-pwa";
import { useUser } from "@clerk/nextjs";
import { motion } from "framer-motion";
import { Heart, Instagram, Linkedin, Mail } from "lucide-react";
import Image from "next/image";
import Link from "next/link";

const placeholders = [
  "Best biryani spot in DFW?",
  "Tell us your desi food guilty pleasures...",
  "That moment when mom video calls during grocery shopping...",
  "Confession: I order mild at Indian restaurants...",
  "Where to find 3AM chai in Dallas?",
  "My Maggi cooking time is still not 2 minutes...",
  "Looking for cricket watching spots in DFW...",
  "Secret menu items at Desi restaurants...",
  "When aunties ask 'beta, shaadi kab?'",
  "Share your hidden gem restaurants...",
  "That feeling when you find samosas in the fridge...",
  "Desperately seeking authentic gol gappas...",
  "Tell us your desi parent WFH stories...",
  "Your go-to order at Indo-Chinese places?",
  "Confession: I still can't fold round rotis..."
];
const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  console.log(e.target.value);
};
const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  console.log("submitted");
};
export default function Welcome() {
  const { isSignedIn } = useUser();
  const isPWA = usePWA()
  return (
    <div className="flex flex-col min-h-screen">

      {/* Hero Section */}
      <div className={`flex-1 relative flex items-start justify-center ${isPWA ? 'pt-0' : 'pt-[10vh]'
        }`}>
        <motion.div
          initial="hidden"
          animate="visible"
          className="text-center w-full px-4 md:px-12 lg:max-w-[66.666667%] space-y-8"
        >
          <div className="space-y-8">
            {/* Welcome text */}
            <motion.h1
              variants={{
                hidden: { opacity: 0, y: 10 },
                visible: { opacity: 1, y: 0 }
              }}
              transition={{ duration: 0.3 }}
              className="text-3xl md:text-5xl lg:text-6xl font-bold text-neutral-700 dark:text-white"
            >
              Welcome to
            </motion.h1>

            {/* Dallaspuram without animation wrapper */}
            <div className="">
              <TextHoverEffect
                text="Dallaspuram"
                highlightText=""
              />
            </div>

            {/* Tagline */}
            <motion.div
              layout
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.7,
                delay: 0.4,
                ease: "easeInOut"
              }}
              className="text-xl md:text-3xl lg:text-4xl text-neutral-600 dark:text-neutral-300 mb-4"
            >
              <PlaceholdersAndVanishInput
                placeholders={placeholders}
              />
            </motion.div>



            {/* Sign Up / Sign In Button or Quick Links */}
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 0 },
                visible: { opacity: 1, y: 0 }
              }}
              transition={{
                duration: 0.7,
                delay: 0.4,
                ease: "easeOut"
              }}
              className="flex flex-col gap-4"
            >
              {isSignedIn ? (
                <>
                  <p className="text-xl text-neutral-600 dark:text-neutral-300">
                    Continue exploring
                  </p>
                  <div className="flex gap-4 justify-center">
                    <Link href="/confessions">
                      <motion.button
                        className="px-6 py-3 font-semibold rounded-lg bg-gradient-to-r from-red-600 via-red-500 to-red-700 text-white"
                        whileHover={{
                          scale: 1.05,
                          transition: { duration: 0.3 },
                        }}
                      >
                        Confessions
                      </motion.button>
                    </Link>
                    <Link href="/restaurants">
                      <motion.button
                        className="px-6 py-3 font-semibold rounded-lg bg-gradient-to-r from-red-600 via-red-500 to-red-700 text-white"
                        whileHover={{
                          scale: 1.05,
                          transition: { duration: 0.3 },
                        }}
                      >
                        Restaurants
                      </motion.button>
                    </Link>
                  </div>
                  <div className="flex flex-col gap-4">
                    <motion.button
                      className="w-fit mx-auto relative group overflow-hidden px-10 sm:px-16 md:px-20 py-2 md:py-4 font-semibold rounded-lg bg-gradient-to-r from-red-600 via-red-500 to-red-700 text-white"
                      whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.3 },
                      }}
                      onClick={() => {
                        document.getElementById('install-app')?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                        });
                      }}
                    >
                      <motion.span
                        className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-red-700 via-red-600 to-red-800 opacity-0 group-hover:opacity-80"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: [0, 1, 0.8] }}
                        transition={{ duration: 0.6 }}
                      />
                      <span className="relative text-md sm:text-xl">Get the app</span>
                    </motion.button>
                  </div>
                </>
              ) : (
                <div className="flex flex-col gap-4">
                  <motion.button
                    className="w-fit mx-auto relative group overflow-hidden px-10 sm:px-16 md:px-20 py-2 md:py-4 font-semibold rounded-lg bg-gradient-to-r from-red-600 via-red-500 to-red-700 text-white"
                    whileHover={{
                      scale: 1.05,
                      transition: { duration: 0.3 },
                    }}
                    onClick={() => {
                      window.location.href = '/sign-in'
                    }}
                  >
                    <motion.span
                      className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-red-700 via-red-600 to-red-800 opacity-0 group-hover:opacity-80"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: [0, 1, 0.8] }}
                      transition={{ duration: 0.6 }}
                    />
                    <span className="relative text-md sm:text-xl">Sign Up / Sign In</span>
                  </motion.button>

                  <div className="flex flex-col gap-4">
                    <motion.button
                      className="w-fit mx-auto relative group overflow-hidden px-10 sm:px-16 md:px-20 py-2 md:py-4 font-semibold rounded-lg bg-gradient-to-r from-red-600 via-red-500 to-red-700 text-white"
                      whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.3 },
                      }}
                      onClick={() => {
                        document.getElementById('install-app')?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                        });
                      }}
                    >
                      <motion.span
                        className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-red-700 via-red-600 to-red-800 opacity-0 group-hover:opacity-80"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: [0, 1, 0.8] }}
                        transition={{ duration: 0.6 }}
                      />
                      <span className="relative text-md sm:text-xl">Get the app</span>
                    </motion.button>
                  </div>
                </div>
              )}
            </motion.div>
          </div>
        </motion.div>
      </div>

      {/* Feature Grid Section */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.3
        }}
        className="w-full px-4 md:px-12 lg:px-[16.666667%] py-12"
      >
        <FeatureGrid />
      </motion.div>

      {/* Footer Section */}
      <motion.footer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.4
        }}
        className={`w-full bg-muted/80 mt-12 ${isPWA ? 'pb-[4rem] md:pb-14' : ''
          }`}
      >
        <div className="container mx-auto px-4 md:px-6 py-12 max-w-7xl">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* About */}
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">About</h3>
              <p className="text-sm text-muted-foreground">
                Building a stronger Indian community in Dallas and beyond. Connect, share, and grow together.
              </p>
            </div>

            {/* Quick Links */}
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">Quick Links</h3>
              <ul className="space-y-2 text-sm">
                {/* <li>
                  <Link href="/about" className="text-muted-foreground hover:text-foreground transition-colors">
                    About Us
                  </Link>
                </li> */}
                <li>
                  <div className="text-muted-foreground hover:text-foreground transition-colors">
                    <ContactDialog trigger={
                      <button className="text-muted-foreground hover:text-foreground transition-colors">
                        Contact Us
                      </button>
                    } />
                  </div>
                </li>
                <li>
                  <div className="flex items-center gap-4 text-muted-foreground hover:text-foreground transition-colors">
                    <PrivacyPolicyDialog />
                  </div>
                </li>
                <li>
                  <div className="flex items-center gap-4 text-muted-foreground hover:text-foreground transition-colors">
                    <TermsOfServiceDialog />
                  </div>
                </li>
              </ul>
            </div>

            {/* Community */}
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">Community</h3>
              <ul className="space-y-2 text-sm">
                <li>
                  <Link href="/confessions" className="text-muted-foreground hover:text-foreground transition-colors">
                    Confessions
                  </Link>
                </li>
                <li>
                  <Link href="/restaurants" className="text-muted-foreground hover:text-foreground transition-colors">
                    Restaurants
                  </Link>
                </li>
                <li>
                  <span className="text-muted-foreground/80 cursor-not-allowed flex items-center gap-1">
                    Housing <span className="text-xs font-medium">(Coming Soon)</span>
                  </span>
                </li>
                <li>
                  <span className="text-muted-foreground/80 cursor-not-allowed flex items-center gap-1">
                    Events <span className="text-xs font-medium">(Coming Soon)</span>
                  </span>
                </li>
              </ul>
            </div>

            {/* Connect */}
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">Connect</h3>
              <div className="flex space-x-4">
                <Link
                  href="https://www.instagram.com/dallaspuram.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Follow us on Instagram"
                  className="text-muted-foreground hover:text-foreground transition-colors"
                >
                  <Instagram className="h-5 w-5" aria-hidden="true" />
                </Link>
                <Link
                  href="https://x.com/lingamaneni_a/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Follow us on X (formerly Twitter)"
                  className="text-muted-foreground hover:text-foreground transition-colors"
                >
                  <Image
                    src="/x.svg"
                    alt="X (formerly Twitter) logo"
                    width={20}
                    height={20}
                    className="dark:invert opacity-50 hover:opacity-75 transition-opacity"
                  />
                </Link>
                {/* <Link 
                  href="https://www.linkedin.com/in/akshaylingamaneni" 
                  target="_blank"
                  className="text-muted-foreground hover:text-foreground transition-colors"
                >
                  <Linkedin className="h-5 w-5" />
                </Link> */}
                <ContactDialog trigger={
                  <button
                    className="text-muted-foreground hover:text-foreground transition-colors"
                    aria-label="Contact us"
                  >
                    <Mail className="h-5 w-5" aria-hidden="true" />
                  </button>
                } />
              </div>
            </div>
          </div>

          {/* Bottom bar */}
          <div className="mt-8 pt-4 border-t border-border">
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <p className="text-sm text-muted-foreground">
                © 2024 Dallaspuram.in. All rights reserved.
              </p>
              <p className="text-sm text-muted-foreground flex items-center gap-1">
                Made with <Heart className="h-4 w-4 text-red-500" /> in Dallas
              </p>
            </div>
          </div>
        </div>
      </motion.footer>
    </div>
  );
}

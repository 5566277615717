"use client";
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import React from "react";
import Autoplay from "embla-carousel-autoplay";
import { MessagesSquare, Heart, Tag, ArrowRight, Users2, Building2, MessageCircle, Bell, Zap, Smartphone, BarChart3, CloudDownload } from "lucide-react";
import { Button } from "@/components/ui/button";
import Image from "next/image";
import { motion } from "framer-motion";
import { Globe } from "./globe";
import Link from "next/link";
import { PwaInstallPrompt } from "@/components/ui/pwa-install-prompt";

export function FeatureGrid() {
  // Create separate plugins for each carousel
  const plugin1 = React.useRef(
    Autoplay({ delay: 5000, stopOnInteraction: true }) as any
  );

  const plugin2 = React.useRef(
    Autoplay({ delay: 5000, stopOnInteraction: true }) as any
  );

  return (
    <section className="py-8 bg-muted/50 rounded-xl">
      <div className="container mx-auto px-4 md:px-6 max-w-7xl space-y-8">
        <div className="space-y-4">
          <h2 className="text-2xl font-bold text-center">
            Explore Dallaspuram.<span className="text-red-600 dark:text-red-500">in</span>
          </h2>
          <p className="text-xl text-center max-w-2xl mx-auto">
            Check out what we have to offer !
          </p>  
        </div>

        <div className="grid gap-8">
          {/* First row - 30/70 split */}
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 sm:gap-6">
            {/* Feature Box */}
            <div className="lg:col-span-5 rounded-xl border p-4 sm:p-6 lg:p-8 flex flex-col justify-between h-[350px] sm:h-[400px] lg:h-[450px] overflow-hidden">
              <div className="overflow-y-auto">
                <h3 className="text-lg sm:text-xl lg:text-2xl font-bold mb-1 sm:mb-2">Anonymous Confessions</h3>
                <p className="text-sm sm:text-sm lg:text-base text-muted-foreground mb-4 sm:mb-6 lg:mb-8">Share your thoughts freely and connect with others</p>

                <div className="space-y-3 sm:space-y-4 lg:space-y-6">
                  <div className="flex items-start gap-2 sm:gap-3">
                    <MessagesSquare className="h-3.5 w-3.5 sm:h-4 sm:w-4 lg:h-5 lg:w-5 text-primary mt-1 flex-shrink-0" />
                    <div>
                      <h4 className="text-base sm:text-sm lg:text-base font-semibold">Anonymous by Default</h4>
                      <p className="text-sm lg:text-sm text-muted-foreground">Express yourself without revealing your identity</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3">
                    <Heart className="h-5 w-5 text-primary mt-1 flex-shrink-0" />
                    <div>
                      <h4 className="text-base font-semibold">Community Engagement</h4>
                      <p className="text-sm text-muted-foreground">Like, comment, and interact with others&apos; confessions</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3">
                    <Tag className="h-5 w-5 text-primary mt-1 flex-shrink-0" />
                    <div>
                      <h4 className="text-base font-semibold">Categorized Content</h4>
                      <p className="text-sm text-muted-foreground">Browse by topics and find relevant discussions</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 sm:mt-6 lg:mt-8 flex-shrink-0">
                <Button 
                  className="w-full text-xs sm:text-sm lg:text-base bg-gradient-to-r from-red-600 via-red-500 to-red-700 text-white hover:opacity-90 transition-opacity" 
                  asChild
                >
                  <Link href="/confessions">
                    Start Sharing
                    <ArrowRight className="ml-2 h-3 w-3 sm:h-4 sm:w-4" />
                  </Link>
                </Button>
              </div>
            </div>

            {/* Carousel */}
            <div className="lg:col-span-7">
              <div className="h-[350px] sm:h-[400px] lg:h-[450px] overflow-hidden">
                <motion.div
                  variants={{
                    hidden: { opacity: 0 },
                    visible: { opacity: 1 }
                  }}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.3,
                    delay: 0.4
                  }}
                >
                  <Carousel
                    opts={{
                      align: "start",
                      loop: true,
                      dragFree: false,
                      skipSnaps: false,
                      containScroll: "trimSnaps"
                    }}
                    plugins={[plugin1.current]}
                    onMouseEnter={() => plugin1.current.stop()}
                    onMouseLeave={() => plugin1.current.play()}
                    className="w-full h-full"
                  >
                    <CarouselContent>
                      <CarouselItem className="basis-full">
                        <div className="rounded-xl border bg-gradient-to-b from-background to-muted p-2 sm:p-4 md:p-6 transition-transform duration-300 hover:scale-[1.01] hover:-translate-y-1">
                          <div className="relative h-[400px] sm:h-[300px] md:h-[350px] lg:h-[400px] rounded-lg overflow-hidden bg-background/50 shadow-xl ring-1 ring-muted">
                            <div className="absolute inset-0 p-4 sm:p-2">
                              {/* Mobile Light Image */}
                              <Image
                                src="/confessions_small_light.png"
                                alt="Carousel 1"
                                width={400}
                                height={400}
                                className="w-full h-full object-contain block sm:hidden dark:hidden"
                               priority
                              />
                              {/* Desktop Light Image */}
                              <Image
                                src="/confessions_light_updated.png"
                                alt="Carousel 1"
                                width={600}
                                height={300}
                                className="w-full h-full object-contain hidden sm:block dark:hidden"
                                priority
                              />
                              {/* Mobile Dark Image */}
                              <Image
                                src="/confessions_small_dark.png"
                                alt="Carousel 1"
                                width={400}
                                height={200}
                                className="w-full h-full object-contain hidden dark:block sm:dark:hidden"
                                priority
                              />
                              {/* Desktop Dark Image */}
                              <Image
                                src="/confessions_dark_updated.png"
                                alt="Carousel 1"
                                width={400}
                                height={200}
                                className="w-full h-full object-contain hidden sm:dark:block"
                                priority
                              />
                            </div>
                            <div className="absolute inset-0 ring-1 ring-inset ring-black/10 rounded-lg" />
                          </div>
                        </div>
                      </CarouselItem>
                      <CarouselItem className="basis-full">
                        <div className="rounded-xl border bg-gradient-to-b from-background to-muted p-4 sm:p-6 transition-transform duration-300 hover:scale-[1.01] hover:-translate-y-1">
                          <div className="relative h-[350px] sm:h-[400px] md:h-[450px] lg:h-[500px] rounded-lg overflow-hidden bg-background/50 shadow-xl ring-1 ring-muted">
                            <Image
                              src="/confessions_post_light.png"
                              alt="Carousel 2"
                              width={400}
                              height={200}
                              className="w-full h-full object-contain block dark:hidden"
                              loading="lazy"
                            />
                            <Image
                              src="/confessions_post.png"
                              alt="Carousel 2"
                              width={400}
                              height={200}
                              className="w-full h-full object-contain hidden dark:block"
                              loading="lazy"
                            />
                            <div className="absolute inset-0 ring-1 ring-inset ring-black/10 rounded-lg" />
                          </div>
                        </div>
                      </CarouselItem>
                      <CarouselItem className="basis-full">
                        <div className="rounded-xl border bg-gradient-to-b from-background to-muted p-4 sm:p-6 transition-transform duration-300 hover:scale-[1.01] hover:-translate-y-1">
                          <div className="relative h-[350px] sm:h-[400px] md:h-[450px] lg:h-[500px] rounded-lg overflow-hidden bg-background/50 shadow-xl ring-1 ring-muted">
                            <Image
                              src="/confessions_sample_light.png"
                              alt="Carousel 3"
                              width={400}
                              height={200}
                              className="w-full h-full object-contain block dark:hidden"
                              loading="lazy"
                            />
                            <Image
                              src="/confessions_sample.png"
                              alt="Carousel 3"
                              width={400}
                              height={200}
                              className="w-full h-full object-contain hidden dark:block"
                              loading="lazy"
                            />
                            <div className="absolute inset-0 ring-1 ring-inset ring-black/10 rounded-lg" />
                          </div>
                        </div>
                      </CarouselItem>
                    </CarouselContent>
                  </Carousel>
                </motion.div>
              </div>
            </div> 
            
          </div>










          <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 sm:gap-6">
            {/* Carousel */}
            <div className="lg:col-span-7">
              <div className="h-[350px] sm:h-[400px] lg:h-[450px] overflow-hidden">
                <motion.div
                  variants={{
                    hidden: { opacity: 0 },
                    visible: { opacity: 1 }
                  }}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.3,
                    delay: 0.4
                  }}
                >
                  <Carousel
                    opts={{
                      align: "start",
                      loop: true,
                      dragFree: false,
                      skipSnaps: false,
                      containScroll: "trimSnaps"
                    }}
                    plugins={[plugin2.current]}
                    onMouseEnter={() => plugin2.current.stop()}
                    onMouseLeave={() => plugin2.current.play()}
                    className="w-full h-full"
                  >
                    <CarouselContent>
                      <CarouselItem className="basis-full">
                        <div className="rounded-xl border bg-gradient-to-b from-background to-muted p-2 sm:p-4 md:p-6 transition-transform duration-300 hover:scale-[1.01] hover:-translate-y-1">
                          <div className="relative h-[400px] sm:h-[300px] md:h-[350px] lg:h-[400px] rounded-lg overflow-hidden bg-background/50 shadow-xl ring-1 ring-muted">
                            <div className="absolute inset-0 p-4 sm:p-2">
                              {/* Mobile Light Image */}
                              <Image
                                src="/restaurant_small_light.png"
                                alt="Carousel 1"
                                width={400}
                                height={200}
                                className="w-full h-full object-contain block sm:hidden dark:hidden"
                                priority
                              />
                              {/* Desktop Light Image */}
                              <Image
                                src="/restaurant_light_updated.png"
                                alt="Carousel 1"
                                width={400}
                                height={200}
                                className="w-full h-full object-contain hidden sm:block dark:hidden"
                                priority
                              />
                              {/* Mobile Dark Image */}
                              <Image
                                src="/restaurant_small_dark.png"
                                alt="Carousel 1"
                                width={400}
                                height={200}
                                className="w-full h-full object-contain hidden dark:block sm:dark:hidden"
                                priority
                              />
                              {/* Desktop Dark Image */}
                              <Image
                                src="/restaurant_dark_updated.png"
                                alt="Carousel 1"
                                width={400}
                                height={200}
                                className="w-full h-full object-contain hidden sm:dark:block"
                                priority
                              />
                            </div>
                            <div className="absolute inset-0 ring-1 ring-inset ring-black/10 rounded-lg" />
                          </div>
                        </div>
                      </CarouselItem>
                      <CarouselItem className="basis-full">
                        <div className="rounded-xl border bg-gradient-to-b from-background to-muted p-2 sm:p-4 md:p-6 transition-transform duration-300 hover:scale-[1.01] hover:-translate-y-1">
                          <div className="relative h-[400px] sm:h-[300px] md:h-[350px] lg:h-[400px] rounded-lg overflow-hidden bg-background/50 shadow-xl ring-1 ring-muted">
                            <div className="absolute inset-0 p-4 sm:p-2">
                              {/* Mobile Light Image */}
                              <Image
                                src="/restaurant_post_light.png"
                                alt="Carousel 1"
                                width={400}
                                height={200}
                                className="w-full h-full object-contain block sm:hidden dark:hidden"
                                loading="lazy"
                              />
                              {/* Desktop Light Image */}
                              <Image
                                src="/restaurant_light.png"
                                alt="Carousel 1"
                                width={400}
                                height={200}
                                className="w-full h-full object-contain hidden sm:block dark:hidden"
                                loading="lazy"
                              />
                              {/* Mobile Dark Image */}
                              <Image
                                src="/restaurant_post_dark.png"
                                alt="Carousel 1"
                                width={400}
                                height={200}
                                className="w-full h-full object-contain hidden dark:block sm:dark:hidden"
                                priority
                              />
                              {/* Desktop Dark Image */}
                              <Image
                                src="/restaurant_dark.png"
                                alt="Carousel 1"
                                width={400}
                                height={200}
                                className="w-full h-full object-contain hidden sm:dark:block"
                                priority
                              />
                            </div>
                            <div className="absolute inset-0 ring-1 ring-inset ring-black/10 rounded-lg" />
                          </div>
                        </div>
                      </CarouselItem>
                    </CarouselContent>
                  </Carousel>
                </motion.div>
              </div>
            </div>
            {/* Feature Box */}
            <div className="lg:col-span-5 rounded-xl border p-4 sm:p-6 lg:p-8 flex flex-col justify-between h-[350px] sm:h-[400px] lg:h-[450px] overflow-hidden">
              <div className="overflow-y-auto">
                <h3 className="text-lg sm:text-xl lg:text-2xl font-bold mb-1 sm:mb-2">Local Restaurants</h3>
                <p className="text-sm sm:text-sm lg:text-base text-muted-foreground mb-4 sm:mb-6 lg:mb-8">Discover authentic Indian cuisine and local favorites</p>

                <div className="space-y-3 sm:space-y-4 lg:space-y-6">
                  <div className="flex items-start gap-2 sm:gap-3">
                    <MessagesSquare className="h-3.5 w-3.5 sm:h-4 sm:w-4 lg:h-5 lg:w-5 text-primary mt-1 flex-shrink-0" />
                    <div>
                      <h4 className="text-base sm:text-sm lg:text-base font-semibold">Curated Reviews</h4>
                      <p className="text-sm lg:text-sm text-muted-foreground">Honest feedback from the community</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3">
                    <Heart className="h-5 w-5 text-primary mt-1 flex-shrink-0" />
                    <div>
                      <h4 className="text-base font-semibold">Location Based</h4>
                      <p className="text-sm text-muted-foreground">Find restaurants near you with easy navigation</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3">
                    <Tag className="h-5 w-5 text-primary mt-1 flex-shrink-0" />
                    <div>
                      <h4 className="text-base font-semibold">Featured Dishes</h4>
                      <p className="text-sm text-muted-foreground">Popular and must-try specialties</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 sm:mt-6 lg:mt-8 flex-shrink-0">
              <Button className="w-full text-xs sm:text-sm lg:text-base bg-gradient-to-r from-red-600 via-red-500 to-red-700 text-white hover:opacity-90 transition-opacity" asChild>
                  <Link href="/restaurants">
                    Explore Restaurants
                    <ArrowRight className="ml-2 h-3 w-3 sm:h-4 sm:w-4" />
                  </Link>
                </Button>
              </div>
            </div>
          </div>
          {/* Third row - full width */}
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
            {/* Why Dallaspuram content */}
            <div className="lg:col-span-6 rounded-xl border p-8 flex flex-col justify-center">
              <h3 className="text-2xl font-bold mb-4">Why Dallaspuram?</h3>

              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
                    <Users2 className="h-4 w-4 text-primary" />
                  </div>
                  <div>
                    <h4 className="text-base sm:text-sm lg:text-base font-semibold">Community First</h4>
                    <p className="text-sm lg:text-sm text-muted-foreground">Building a strong, connected community in Dallas and beyond</p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
                    <Building2 className="h-4 w-4 text-primary" />
                  </div>
                  <div>
                    <h4 className="text-base sm:text-sm lg:text-base font-semibold">Local Insights</h4>
                    <p className="text-sm lg:text-sm text-muted-foreground">Curated information about restaurants, events, and services</p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
                    <MessageCircle className="h-4 w-4 text-primary" />
                  </div>
                  <div>
                    <h4 className="text-base sm:text-sm lg:text-base font-semibold">Open Discussions</h4>
                    <p className="text-sm lg:text-sm text-muted-foreground">Safe space for sharing experiences and getting advice</p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
                    <BarChart3 className="h-4 w-4 text-primary" />
                  </div>
                  <div>
                    <h4 className="text-base sm:text-sm lg:text-base font-semibold">Data Aggregation</h4>
                    <p className="text-sm lg:text-sm text-muted-foreground">Aggregating data from multiple sources</p>
                  </div>
                </div>

                {/* <div className="flex items-start gap-4">
                  <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
                    <Globe2 className="h-4 w-4 text-primary" />
                  </div>
                  <div>
                    <h4 className="text-base sm:text-sm lg:text-base font-semibold">Cultural Bridge</h4>
                    <p className="text-sm lg:text-sm text-muted-foreground">Connecting people across the USA</p>
                  </div>
                </div> */}
              </div>
            </div>

            {/* Globe container */}
            <div className="lg:col-span-6 rounded-xl border overflow-hidden relative">
              <motion.div
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 }
                }}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{
                  duration: 1.3,
                  delay: 0.4
                }}
                className="w-full h-full min-h-[350px] md:min-h-[600px] lg:min-h-[350px] max-h-[600px] aspect-[4/3]"
              >
                <Globe />
              </motion.div>

              {/* Overlay text */}
              <motion.div
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0 }
                }}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{
                  duration: 0.8,
                  delay: 1.2
                }}
                className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-background/90 to-transparent"
              >
                <div className="flex items-center gap-3 text-sm">
                  <div className="flex -space-x-2">
                    <div className="h-6 w-6 rounded-full bg-primary/20 flex items-center justify-center">
                      <span className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-primary opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-2 w-2 bg-primary"></span>
                    </div>
                  </div>
                  <p className="text-muted-foreground dark:text-muted-foreground/80">
                    <span className="font-semibold text-foreground dark:text-foreground/90">Building</span> a global network 
                  </p>
                </div>
              </motion.div>

              {/* Optional: Top stats */}
              <motion.div
                variants={{
                  hidden: { opacity: 0, y: -20 },
                  visible: { opacity: 1, y: 0 }
                }}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{
                  duration: 0.8,
                  delay: 2.0
                }}
                className="absolute top-0 right-0 p-6"
              >
                <div className="bg-background/80 backdrop-blur-sm rounded-lg p-3 shadow-lg">
                  <div className="text-sm font-medium">Let&apos;s Build Together</div>
                  <div className="text-2xl font-bold text-primary">Join Us</div>
                  <div className="text-xs text-muted-foreground">Be Part of Our Journey</div>
                </div>
              </motion.div>
            </div>
          </div>
          {/* Fourth row - 50/50 split */}
          <div id="install-app" className="grid grid-cols-1 lg:grid-cols-12 gap-6">
            {/* Install App Card */}
            <div className="lg:col-span-6 rounded-xl border p-8 flex flex-col justify-center">
              <h3 className="text-2xl font-bold mb-4">Install Dallaspuram App</h3>
              <p className="text-sm text-muted-foreground mb-6">
                We have built a Progressive Web App (PWA) that combines the best of web and mobile. It is light weight and functions like a native app. With less than 2MB of storage required, it is easy to install and use.
              </p>
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
                    <Zap className="h-4 w-4 text-primary" />
                  </div>
                  <div>
                    <h4 className="text-base sm:text-sm lg:text-base font-semibold">Quick Installation</h4>
                    <p className="text-sm lg:text-sm text-muted-foreground">Install directly from your browser - no app store needed</p>
                  </div>
                </div>

                {/* <div className="flex items-start gap-4">
                  <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
                    <Zap className="h-4 w-4 text-primary" />
                  </div>
                  <div>
                    <h4 className="text-base sm:text-sm lg:text-base font-semibold">Instant Access</h4>
                    <p className="text-sm lg:text-sm text-muted-foreground">Access directly from your home screen</p>
                  </div>
                </div> */}

                <PwaInstallPrompt />
              </div>
            </div>

            {/* Why PWA Card */}
            <div className="lg:col-span-6 rounded-xl border p-8 flex flex-col justify-center">
              <h3 className="text-2xl font-bold mb-4">Why install from browser?</h3>
          
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
                    <Smartphone className="h-4 w-4 text-primary" />
                  </div>
                  <div>
                    <h4 className="text-base sm:text-sm lg:text-base font-semibold">Native Experience</h4>
                    <p className="text-sm lg:text-sm text-muted-foreground">Feels and works like a native mobile app</p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
                    <Bell className="h-4 w-4 text-primary" />
                  </div>
                  <div>
                    <h4 className="text-base sm:text-sm lg:text-base font-semibold">No App Updates Required</h4>
                    <p className="text-sm lg:text-sm text-muted-foreground">No need to update the app, updates are pushed in the background automatically</p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
                    <CloudDownload className="h-4 w-4 text-primary" />
                  </div>
                  <div>
                    <h4 className="text-base sm:text-sm lg:text-base font-semibold">Lightweight</h4>
                    <p className="text-sm lg:text-sm text-muted-foreground">Less than 2MB of storage required</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
} 
'use client'

import { useCallback, useEffect, useState } from 'react'
import { ArrowDownCircle } from 'lucide-react'
import { cn } from '@/lib/utils'

const MAXIMUM_PULL_LENGTH = 180
const REFRESH_THRESHOLD = 120

export function PullToRefreshProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [pullStartPosition, setPullStartPosition] = useState(0)
  const [pullPosition, setPullPosition] = useState(0)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const onPullStart = useCallback(({ targetTouches }: TouchEvent) => {
    const touch = targetTouches[0]
    if (touch) setPullStartPosition(touch.screenY)
  }, [])

  const onPulling = useCallback(({ targetTouches }: TouchEvent) => {
    const touch = targetTouches[0]
    if (!touch) return

    const currentPullLength = pullStartPosition < touch.screenY 
      ? Math.abs(touch.screenY - pullStartPosition) 
      : 0

    if (currentPullLength <= MAXIMUM_PULL_LENGTH && pullStartPosition < window.screen.height / 2) {
      setPullPosition(currentPullLength)
    }
  }, [pullStartPosition])

  const onEndPull = useCallback(() => {
    setPullStartPosition(0)
    setPullPosition(0)

    if (pullPosition < REFRESH_THRESHOLD) return

    setIsRefreshing(true)
    setTimeout(() => {
      // Force a full page reload
      window.location.reload()
    }, 500)
  }, [pullPosition])

  useEffect(() => {
    if (typeof window === 'undefined') return

    const ac = new AbortController()
    const options = {
      passive: true,
      signal: ac.signal
    }

    window.addEventListener('touchstart', onPullStart, options)
    window.addEventListener('touchmove', onPulling, options)
    window.addEventListener('touchend', onEndPull, options)

    return () => void ac.abort()
  }, [onEndPull, onPullStart, onPulling])

  return (
    <>
      <div 
        className={cn(
          "fixed top-0 left-1/2 -translate-x-1/2 z-50",
          "transition-all duration-300"
        )}
        style={{
          top: (isRefreshing ? REFRESH_THRESHOLD : pullPosition) / 3,
          opacity: isRefreshing || pullPosition > 0 ? 1 : 0
        }}
      >
        <div className="bg-primary rounded-full p-2 shadow-lg">
          <ArrowDownCircle 
            className={cn(
              "w-6 h-6 text-primary-foreground",
              isRefreshing ? 'animate-spin' : ''
            )}
            style={!isRefreshing ? { 
              transform: `rotate(${pullPosition}deg)` 
            } : undefined}
          />
        </div>
      </div>
      {children}
    </>
  )
} 
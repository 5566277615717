"use client";

import { useEffect, useRef, useState } from 'react';
import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from '@tanstack/react-query';
import { NotificationsService } from '@/components/notifications/notifications-service';
import { useNotifications } from '@/hooks/use-notifications';
import { useUser } from '@clerk/nextjs';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Bell, MoreHorizontal } from "lucide-react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { NotificationItem } from "./notification-item";
import { sendNotification } from '@/utils/push-notifications';

interface NotificationsComponentProps {
  variant?: 'mobile' | 'desktop';
}

export function NotificationsComponent({ variant = 'desktop' }: NotificationsComponentProps) {
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  const queryClient = useQueryClient();
  const notificationService = new NotificationsService();

  // Subscribe to real-time notifications
  useNotifications(user?.id as string);
  const previousCount = useRef(0);
  // Fetch notifications with pagination
  const { data: notifications, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ['notifications'],
    queryFn: ({ pageParam = 1 }) => notificationService.getNotifications(user?.id as string, pageParam),
    getNextPageParam: (lastPage, pages) => lastPage.hasMore ? pages.length + 1 : undefined,
    enabled: !!user?.id,
    initialData: { pages: [{ today: [], earlier: [], hasMore: false }], pageParams: [1] },
    refetchInterval: 900000,       // Poll every 15 minutes (900000ms)
    refetchOnWindowFocus: true,    // Update when user returns to tab
    staleTime: 0,                 // Always fetch fresh data on mount
    cacheTime: 900000,            // Cache for 15 minutes
    refetchOnMount: 'always',     // Always fetch when component mounts
    refetchOnReconnect: true,     // Update when internet reconnects
    initialDataUpdatedAt: 0  
  });
  
  
  // useEffect(() => {
  //   console.log("NotificationsComponent useEffect", notifications)
  //   if (!notifications) return;

  //   const currentCount = notifications.pages.reduce((total, page) => 
  //     total + page.today.length + page.earlier.length, 0);

  //   if (currentCount > previousCount.current && previousCount.current !== 0) {
  //     const newNotification = notifications.pages[0].today[0];
  //     console.log("newNotification", newNotification)
  //     // Send push notification
  //     sendNotification(newNotification.message).then(result => {
  //       console.log('Push notification result:', result);
  //     }).catch(error => {
  //       console.error('Error sending push notification:', error);
        
  //       // Fallback to service worker notification if push fails
  //       if ('serviceWorker' in navigator) {
  //         navigator.serviceWorker.ready.then(registration => {
  //           registration.showNotification(newNotification.title || 'New Notification', {
  //             body: newNotification.message,
  //             icon: '/icon.png',
  //             tag: newNotification.id,
  //             data: {
  //               url: newNotification.link
  //             }
  //           });
  //         });
  //       }
  //     });
  //   }

  //   previousCount.current = currentCount;
  // }, [notifications]);

  // Calculate unread count from all pages
  const unreadCount = notifications?.pages.reduce((count, page) => 
    count + (page.today?.filter((n: any) => !n.read).length || 0), 0) || 0;

  // Mark all as read mutation
  const markAllAsRead = useMutation({
    mutationFn: () => notificationService.markAllAsRead(user?.id as string),
    onSuccess: () => {
      // Invalidate and refetch notifications to update UI
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });

  // Handle body scroll lock for mobile
  useEffect(() => {
    if (variant === 'mobile') {
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  }, [open, variant]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {variant === 'mobile' ? (
          <button className="flex flex-col items-center justify-center text-muted-foreground">
            <div className="relative">
              <Bell className="h-5 w-5" />
              {unreadCount > 0 && (
                <span className="absolute -top-1 -right-1 h-4 w-4 rounded-full bg-red-500 text-[10px] font-medium text-white flex items-center justify-center">
                  {unreadCount}
                </span>
              )}
            </div>
            <span className="text-xs mt-1">Notifications</span>
          </button>
        ) : (
          <Button variant="ghost" size="icon" className="relative">
            <Bell className="h-5 w-5" />
            {unreadCount > 0 && (
              <span className="absolute -top-1 -right-1 h-4 w-4 rounded-full bg-red-500 text-[10px] font-medium text-white flex items-center justify-center">
                {unreadCount}
              </span>
            )}
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent 
        className="w-[380px] p-0" 
        align={variant === 'mobile' ? 'center' : 'end'}
        alignOffset={variant === 'mobile' ? 0 : -15}
        side={variant === 'mobile' ? 'top' : 'bottom'}
        sideOffset={variant === 'mobile' ? 4 : 10}
      >
        <div className="flex items-center justify-between px-4 py-3 border-b">
          <h2 className="text-sm font-medium">Notifications</h2>
          <Button 
            variant="ghost" 
            size="sm" 
            className="text-xs font-medium text-muted-foreground hover:text-primary transition-colors"
            onClick={() => markAllAsRead.mutate()}
          >
            Mark all as read
          </Button>
        </div>

        <ScrollArea className="h-[400px]">
          <div>
            {/* Today's notifications - only from first page */}
            {notifications?.pages[0].today.length > 0 && (
              <>
                <div className="px-4 py-2">
                  <h4 className="text-xs font-medium text-muted-foreground">TODAY</h4>
                </div>
                <div className="bg-muted/30">
                  {notifications?.pages[0].today.map((notification: any) => (
                    <NotificationItem 
                      key={notification.id}
                      notification={notification}
                    />
                  ))}
                </div>
              </>
            )}

            {/* Earlier notifications - combined from all pages */}
            {notifications?.pages.some(page => page.earlier.length > 0) && (
              <>
                <div className="px-4 py-2">
                  <h4 className="text-xs font-medium text-muted-foreground">EARLIER</h4>
                </div>
                <div className="bg-background">
                  {notifications.pages.map((page) => 
                    page.earlier.map((notification: any) => (
                      <NotificationItem 
                        key={notification.id}
                        notification={notification}
                      />
                    ))
                  )}
                </div>
              </>
            )}

            {/* Load more trigger */}
            {hasNextPage && (
              <div
                ref={(el) => {
                  if (!el) return;
                  const observer = new IntersectionObserver(
                    (entries) => {
                      if (entries[0].isIntersecting && !isFetchingNextPage) {
                        fetchNextPage();
                      }
                    },
                    { threshold: 0.1 }
                  );
                  observer.observe(el);
                  return () => observer.disconnect();
                }}
                className="h-10"
              />
            )}

            {isFetchingNextPage ? (
              <div className="flex items-center justify-center py-4">
                <span className="text-sm text-muted-foreground">Loading more...</span>
              </div>
            ) : !hasNextPage && notifications?.pages[0].today.length + notifications?.pages.flatMap(p => p.earlier).length > 0 && (
              <div className="flex items-center justify-center py-4">
                <span className="text-sm text-muted-foreground">All caught up! ✨</span>
              </div>
            )}
          </div>
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
}
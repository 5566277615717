import { useRouter, usePathname } from 'next/navigation';
import { NotificationsService } from './notifications-service';
import { useQueryClient } from '@tanstack/react-query';
import { Check, MoreHorizontal, Trash } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";

export function NotificationItem({ notification }: { notification: any }) {
  const router = useRouter();
  const pathname = usePathname();
  const queryClient = useQueryClient();
  const notificationService = new NotificationsService();

  const handleClick = async () => {
    // Mark as read and navigate
    if (!notification.read) {
      await notificationService.markAsRead(notification.id);
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    }
    if (notification.data.link) {
      // Use push for client-side navigation
      router.push(notification.data.link);
    }
  };

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await notificationService.deleteNotification(notification.id);
    queryClient.invalidateQueries({ queryKey: ['notifications'] });
  };

  const handleMarkAsRead = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await notificationService.markAsRead(notification.id);
    queryClient.invalidateQueries({ queryKey: ['notifications'] });
  };

  return (
    <div 
      className={`px-4 py-3 flex items-center justify-between hover:bg-muted/50 cursor-pointer transition-colors ${
        !notification.read ? 'bg-muted/20' : ''
      }`}
      onClick={handleClick}
    >
      <div className="flex-1">
        <p className="text-sm font-medium">{notification.data.title}</p>
        <p className="text-sm text-muted-foreground">{notification.data.message}</p>
      </div>
      
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button 
            variant="ghost" 
            size="icon"
            onClick={(e) => e.stopPropagation()}
          >
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={handleMarkAsRead}>
            <Check className="h-4 w-4 mr-2" />
            Mark as read
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleDelete}>
            <Trash className="h-4 w-4 mr-2" />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
} 
'use client'

import { useState, useEffect } from 'react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Download, Share2, Plus } from "lucide-react"
import { useBrowserInfo } from '@/utils/use-browser-info'

export function PwaInstallPrompt() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const { name: browser, isMobile } = useBrowserInfo();

  return (
    <div className="space-y-6">
      {browser === 'Safari' ? (
        <div className="flex items-start gap-4">
          <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
            <Share2 className="h-4 w-4 text-primary" />
          </div>
          <div>
            <h4 className="text-base font-semibold">On Safari:</h4>
            <div className="text-sm text-muted-foreground space-y-1">
              <p>1. Click on Share {isMobile ? 'at the bottom' : 'in the toolbar'}</p>
              <p>2. Select {isMobile ? '"Add to Home Screen"' : '"Add to Dock"'}</p>
            </div>
          </div>
        </div>
      ) : browser === 'Chrome' ? (
        <div className="flex items-start gap-4">
          <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
            <Download className="h-4 w-4 text-primary" />
          </div>
          <div>
            <h4 className="text-base font-semibold">On Chrome:</h4>
            <p className="text-sm text-muted-foreground">
              {isMobile ? 'Tap "Install Dallaspuram" at the bottom' : 'Click "Install" icon next to bookmarks'}
            </p>
          </div>
        </div>
      ) : browser === 'Firefox' ? (
        <div className="flex items-start gap-4">
          <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
            <Plus className="h-4 w-4 text-primary" />
          </div>
          <div>
            <h4 className="text-base font-semibold">On Firefox:</h4>
            <p className="text-sm text-muted-foreground">
              {isMobile ? 'Tap' : 'Click'} the &quot;+&quot; icon in the address bar
            </p>
          </div>
        </div>
      ) : browser === 'Edge' ? (
        <div className="flex items-start gap-4">
          <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
            <Download className="h-4 w-4 text-primary" />
          </div>
          <div>
            <h4 className="text-base font-semibold">On Edge:</h4>
            <p className="text-sm text-muted-foreground">
              {isMobile ? 'Tap "Add to Home Screen" in browser menu' : 'Click "Install" icon in the address bar'}
            </p>
          </div>
        </div>
      ) : deferredPrompt ? (
        <div className="flex items-start gap-4">
          <div className="h-8 w-8 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
            <Download className="h-4 w-4 text-primary" />
          </div>
          <div>
            <h4 className="text-base font-semibold">On {browser}:</h4>
            <p className="text-sm text-muted-foreground">Click &quot;Install&quot; button when prompted</p>
          </div>
        </div>
      ) : null}
    </div>
  );
}
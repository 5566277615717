'use client'

import { experimental__simple } from "@clerk/themes"
import { dark } from "@clerk/themes"
import { SignInButton, SignOutButton, useUser } from "@clerk/nextjs"
import { Home, MessageSquare, Utensils, User, LogOut, MoreVertical, Settings, Sun, Moon, LogIn } from "lucide-react"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { useEffect, useState } from "react"
import { useTheme } from "next-themes"
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import ThemeSwitch from "../icons/theme-switch"
import { NotificationsComponent } from "../notifications/notifications-component"

export function PwaNavBar() {
  const pathname = usePathname()
  const [isStandalone, setIsStandalone] = useState(false)
  const { user } = useUser()

  useEffect(() => {
    setIsStandalone(window.matchMedia('(display-mode: standalone)').matches)

    const mediaQuery = window.matchMedia('(display-mode: standalone)')
    const handleChange = (e: MediaQueryListEvent) => setIsStandalone(e.matches)
    mediaQuery.addEventListener('change', handleChange)
    
    return () => mediaQuery.removeEventListener('change', handleChange)
  }, [])

  if (!isStandalone) return null

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-background border-t border-border h-20 pb-safe">
      <div className={`grid ${user?.id ? 'grid-cols-5' : 'grid-cols-4'} h-full max-w-md mx-auto pb-[1rem]`}>
        <Link 
          href="/"
          className={`flex flex-col items-center justify-center ${
            pathname === '/' ? 'text-primary' : 'text-muted-foreground'
          }`}
        >
          <Home className="h-5 w-5" />
          <span className="text-xs mt-1">Home</span>
        </Link>
        
        <Link 
          href="/confessions"
          className={`flex flex-col items-center justify-center ${
            pathname.includes('/confessions') ? 'text-primary' : 'text-muted-foreground'
          }`}
        >
          <MessageSquare className="h-5 w-5" />
          <span className="text-xs mt-1">Confessions</span>
        </Link>

        <Link 
          href="/restaurants"
          className={`flex flex-col items-center justify-center ${
            pathname.includes('/restaurants') ? 'text-primary' : 'text-muted-foreground'
          }`}
        >
          <Utensils className="h-5 w-5" />
          <span className="text-xs mt-1">Restaurants</span>
        </Link>

        {user?.id && <NotificationsComponent variant="mobile" />}

        <Popover>
          <PopoverTrigger className={`flex flex-col items-center justify-center ${
            pathname.includes('/user-profile') ? 'text-primary' : 'text-muted-foreground'
          }`}>
            <MoreVertical className="h-5 w-5" />
            <span className="text-xs mt-1">More</span>
          </PopoverTrigger>
          <PopoverContent className="w-48 p-2" side="top">
            
            <div className="flex flex-col gap-3">
              {user?.id ? (
                <SignOutButton>
                  <button 
                    className="w-full flex items-center gap-2 p-2 hover:bg-muted rounded-md text-sm text-destructive"
                  >
                    <LogOut className="h-5 w-5" />
                    Sign Out
                  </button>
                </SignOutButton>
              ) : (
                <SignInButton>
                  <button 
                    className="w-full flex items-center gap-2 p-2 hover:bg-muted rounded-md text-sm text-green-500"
                  >
                    <LogIn className="h-5 w-5" />
                    Sign In
                  </button>
                </SignInButton>
              )}
              {user?.id && (
                <Link 
                  href="/user-profile"
                  className="flex items-center gap-2 p-2 hover:bg-muted rounded-md text-sm"
              >
                <User className="h-5 w-5" />
                Profile
                </Link>
              )}
              <ThemeSwitch />
              {/* <ThemeSwitch className="flex items-center gap-2 p-2 hover:bg-muted rounded-md text-sm">
                <Sun className="h-5 w-5 dark:hidden" />
                <Moon className="h-5 w-5 hidden dark:block" />
                Theme
              </ThemeSwitch> */}

            </div>
          </PopoverContent>
        </Popover>
      </div>
    </nav>
  )
} 
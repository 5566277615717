"use client"

import { UserButton, useUser } from '@clerk/nextjs'
import { dark, experimental__simple } from '@clerk/themes'
import { motion } from "framer-motion"
import { Menu } from "lucide-react"
import { useTheme } from "next-themes"
import Link from "next/link"
import { FaFilterCircleDollar } from "react-icons/fa6"
import ThemeSwitch from '../icons/theme-switch'
import { Button } from "../ui/button"
import { Sheet, SheetContent, SheetTrigger } from "../ui/sheet"
import { usePWA } from '@/hooks/use-pwa'
import { useEffect, useState } from 'react'
import { NotificationsComponent } from '../notifications/notifications-component'

export function NavMenu() {
  const { theme, setTheme } = useTheme()
  const isPWA = usePWA()
  const [shouldRender, setShouldRender] = useState(false)
  const { user } = useUser()

  useEffect(() => {
    // Only show if not in PWA mode
    setShouldRender(!isPWA)
  }, [isPWA])

  // Don't render anything until we've checked PWA status
  if (!shouldRender) return null

  return (
    <div className="fixed top-0 w-full z-50 bg-background border-b">
      <div className="container mx-auto">
        <header className="flex h-14 items-center px-4 md:px-6">
          {/* Left Side */}
          <div className="flex items-center">
            {/* Mobile Menu Button (only on small screens) */}
            <Sheet>
              <SheetTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className="md:hidden"
                >
                  <Menu className="h-5 w-5" />
                  <span className="sr-only">Toggle navigation menu</span>
                </Button>
              </SheetTrigger>
              <SheetContent side="left">
                {/* Mobile Navigation */}
                <nav className="grid gap-6 text-lg font-medium">
                  <Link
                    href="/"
                    className="flex items-center gap-2 text-lg font-semibold"
                  >
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    >
                      <FaFilterCircleDollar className="text-[#db0000] h-6 w-6" />
                    </motion.div>
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    >
                      Dallaspuram
                    </motion.span>
                  </Link>
                  <Link
                    href="/confessions"
                    className="flex items-center gap-3 rounded-lg px-2 py-2 text-muted-foreground transition-all hover:bg-muted hover:text-primary focus:bg-muted focus:outline-slate-400"
                  >
                    Confessions
                  </Link>
                  <Link
                    href="/restaurants"
                    className="flex items-center gap-3 rounded-lg -mt-5 px-2 py-2 text-muted-foreground transition-all hover:bg-muted hover:text-primary focus:bg-muted focus:outline-slate-400"
                  >
                    Restaurants
                  </Link>
                  {/* <Link
                    href="#"
                    className="flex items-center gap-3 rounded-lg -mt-5 px-2 py-2 text-muted-foreground transition-all hover:bg-muted hover:text-primary focus:bg-muted focus:outline-slate-400 pointer-events-none"
                  >
                    Housing (Coming Soon)
                  </Link> */}
                  {/* <Link
                    href="#"
                    className="flex items-center gap-3 rounded-lg -mt-5 px-2 py-2 text-muted-foreground transition-all hover:bg-muted hover:text-primary focus:bg-muted focus:outline-slate-400"
                  >
                    Customers
                  </Link> */}
                  {user?.id &&
                  <Link
                    href="/user-profile"
                    className="flex items-center gap-3 rounded-lg -mt-5 px-2 py-2 text-muted-foreground transition-all hover:bg-muted hover:text-primary focus:bg-muted focus:outline-slate-400"
                  >
                    Settings
                  </Link>
                  }
                </nav>
              </SheetContent>
            </Sheet>

            {/* Logo */}
            <Link
              href="/"
              className="flex items-center gap-2 text-lg font-semibold md:text-base ml-2"
            >
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
              >
                <FaFilterCircleDollar className="text-[#db0000] h-6 w-6" />
              </motion.div>
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Dallaspuram
              </motion.span>
            </Link>
          </div>

          {/* Navigation Links (centered on md and above) */}
          <nav className="hidden md:flex flex-grow justify-center space-x-5">
            <Link
              href="/confessions"
              className="flex items-center gap-3 rounded-lg px-2 py-2 text-muted-foreground transition-all hover:bg-muted hover:text-primary focus:bg-muted focus:outline-slate-400"
            >
              Confessions
            </Link>
            <Link
              href="/restaurants"
              className="flex items-center gap-3 rounded-lg px-2 py-2 text-muted-foreground transition-all hover:bg-muted hover:text-primary focus:bg-muted focus:outline-slate-400"
            >
              Restaurants
            </Link>
            {/* <Link
              href="#"
              className="flex items-center gap-3 rounded-lg px-2 py-2 text-muted-foreground transition-all hover:bg-muted hover:text-primary focus:bg-muted focus:outline-slate-400 pointer-events-none"
            >
              Housing (Coming Soon)
            </Link> */}
            {/* <Link
              href="#"
              className="flex items-center gap-3 rounded-lg px-2 py-2 text-muted-foreground transition-all hover:bg-muted hover:text-primary focus:bg-muted focus:outline-slate-400"
            >
              Customers
            </Link> */}
            {user?.id &&
            <Link
              href="/user-profile"
              className="flex items-center gap-3 rounded-lg px-2 py-2 text-muted-foreground transition-all hover:bg-muted hover:text-primary focus:bg-muted focus:outline-slate-400"
            >
              Settings
            </Link>
            }
          </nav>

          {/* Right Side */}
          <div className="flex items-center ml-auto gap-2">
            {/* Theme Toggle */}
            <ThemeSwitch />
            {/* User Menu */}
            {user?.id && <NotificationsComponent variant="desktop" />}
            <UserButton appearance={{
              baseTheme: theme === "dark" ? dark : experimental__simple,
            }}
              userProfileMode='navigation'
              userProfileUrl='/user-profile'
            />
          </div>
        </header>
      </div>
    </div>
  )
}

"use client";

import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

export function PlaceholdersAndVanishInput({
  placeholders,
  delay = 2000,
}: {
  placeholders: string[];
  delay?: number;
}) {
  const [currentPlaceholder, setCurrentPlaceholder] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const startAnimation = () => {
    intervalRef.current = setInterval(() => {
      setCurrentPlaceholder((prev) => (prev + 1) % placeholders.length);
    }, delay);
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState !== "visible" && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    } else if (document.visibilityState === "visible") {
      startAnimation();
    }
  };

  useEffect(() => {
    startAnimation();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [placeholders, delay]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full relative max-w-xl mx-auto h-12 rounded-full overflow-visible transition duration-200"
      style={{
        background: 'linear-gradient(-45deg, rgba(255,51,102,0.25), rgba(186,38,93,0.25), rgba(153,50,204,0.25), rgba(123,104,238,0.25))',
        backgroundSize: '300% 300%',
        animation: 'gradient 20s ease infinite',
      }}
    >
      <style jsx>{`
        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        @keyframes borderFlow {
          0% {
            box-shadow: 0 0 0 1px rgba(255,255,255,0.08);
            transform: scale(1);
          }
          50% {
            box-shadow: 0 0 0 2px rgba(255,255,255,0.05);
            transform: scale(1.001);
          }
          100% {
            box-shadow: 0 0 0 1px rgba(255,255,255,0.08);
            transform: scale(1);
          }
        }
      `}</style>
      <div 
        className="absolute inset-0 flex items-center rounded-full bg-white/10 backdrop-blur-sm"
        style={{
          animation: 'borderFlow 4s ease-in-out infinite',
        }}
      >
        <AnimatePresence mode="wait">
          <motion.p
            initial={{
              y: 5,
              opacity: 0,
            }}
            key={`current-placeholder-${currentPlaceholder}`}
            animate={{
              y: 0,
              opacity: 1,
            }}
            exit={{
              y: -15,
              opacity: 0,
            }}
            transition={{
              duration: 0.3,
              ease: "linear",
            }}
            className="text-foreground/80 text-sm sm:text-base font-normal pl-4 sm:pl-12 text-left w-[calc(100%-2rem)] truncate relative z-10"
          >
            {placeholders[currentPlaceholder]}
          </motion.p>
        </AnimatePresence>
      </div>
    </motion.div>
  );
}

import prisma from "@/lib/db/prisma"
import { Notification, NotificationType } from "@/types/notifications";
import { pusherServer } from "@/components/notifications/pusher";
import { ParentType } from "@/types/confession";
import { sendNotification } from "@/utils/push-notifications";

export class NotificationsService {
  async createNotification({
    type,
    userId,
    actorId,
    data,
  }: {
    type: NotificationType;
    userId: string;
    actorId?: string;
    data: {
      title: string;
      message: string;
      link?: string;
      imageUrl?: string;
      targetId?: string;
      targetType?: string;
      parentCommentId?: string;
    };
  }) {
    console.log("createNotification", type, userId, actorId, data)
    // Create notification in database
    const notification = await prisma.notification.create({
      data: {
        type,
        userId,
        actorId,
        read: false,
        data
      }
    });

    // Send push notification to subscribed devices
    const pushSubscription = await prisma.pushSubscription.findFirst({
      where: { userId }
    });

    if (pushSubscription) {
      await sendNotification(userId, data); // Using your push-notifications utility
    }

    // Also trigger Pusher for real-time updates
    await pusherServer.trigger(
      `private-user-${userId}`,
      'notification',
      notification
    );

    return notification;
  }

  async getNotifications(userId: string, page = 1) {
    const response = await fetch(`/api/notifications?userId=${userId}&page=${page}`);
    if (!response.ok) throw new Error('Failed to fetch notifications');
    return response.json();
  }

  async markAsRead(notificationId: string) {
    const response = await fetch(`/api/notifications/${notificationId}/mark-read`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) throw new Error('Failed to mark notification as read');
    return response.json();
  }

  async markAllAsRead(userId: string) {
    const response = await fetch('/api/notifications/mark-all-as-read', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId })
    });

    if (!response.ok) throw new Error('Failed to mark notifications as read');
    return response.json();
  }

  private async sendPushNotification(
    subscription: any,
    notification: { title: string; body: string; url?: string }
  ) {
    try {
      await fetch('/api/push/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subscription,
          notification: {
            title: notification.title,
            body: notification.body,
            icon: '/icon.png',
            data: {
              url: notification.url
            }
          }
        }),
      });
    } catch (error) {
      console.error('Error sending push notification:', error);
    }
  }

  async notifyLike({
    userId,    // User who receives the notification
    actorId,   // User who liked
    postId,    // Post that was liked
    postTitle, // Title or preview of the post
    parentType,  // Type of the parent (post or comment)
    userName, // Name of the actor
    parentCommentId
  }: {
    userId: string;
    actorId: string;
    postId: string;
    postTitle: string;
    parentType: string;
    userName: string;
    parentCommentId?: string;
  }) {
    let actor;
    if(parentType === ParentType.CONFESSION) {
      actor = await prisma.userConfessionSettings.findUnique({
        where: { userId: actorId },
        select: { confessionUsername: true }
      });
    } else {
      actor = {
        confessionUsername: userName
      }
    }
    return this.createNotification({
      type: 'LIKE',
      userId,
      actorId,
      data: {
        title: `@${actor?.confessionUsername} liked your post 👍`,
        message: '',
        link: `/confessions/posts/${postId}#${parentCommentId}`,
        targetId: postId,
        targetType: 'post',
        parentCommentId
      }
    });
  }

  async notifyComment({
    userId,
    userName,
    parentType,
    actorId,
    postId,
    comment,
    parentCommentId
  }: {
    userId: string;
    actorId: string;
    userName: string;
    parentType: string;
    postId: string;
    comment: string;
    parentCommentId?: string;
  }) {
    let actor;
    if(parentType === 'post') {
      actor = await prisma.userConfessionSettings.findUnique({
        where: { id: actorId },
        select: { confessionUsername: true }
      });
    } else {
      actor = {
        confessionUsername: userName
      }
    }

    return this.createNotification({
      type: 'COMMENT',
      userId,
      actorId,
      data: {
        title: `@${actor?.confessionUsername} commented on your post 💬`,
        message: '',
        link: `/confessions/posts/${postId}#${parentCommentId}`,
        targetId: postId,
        targetType: 'post',
        parentCommentId
      }
    });
  }

  async deleteNotification(notificationId: string) {
    const response = await fetch(`/api/notifications/${notificationId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) throw new Error('Failed to delete notification');
    return response.json();
  }
}
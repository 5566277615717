export interface Confession {
    id: string;
    title: string;
    content: string;
    tags?: string[];
    user: User;
    postType: ParentType;
    createdAt: Date;
    updatedAt?: Date | null;
    comments: Comment[];
    likeCount: number;
    likedBy?: string[];
    viewCount: number;
    labels: string[];
    isHot: boolean;
}
export interface User {
    id: string;
    username: string;
}
export enum ParentType {
    CONFESSION = 'confession',
    RESTAURANT = 'restaurant',
  }
// Base comment interface
export interface BaseComment {
    id: string;
    content: string;
    user: User;
    createdAt: Date;
    updatedAt?: Date;
    replies?: Comment[];
    likeCount?: number;
    commentCount?: number;
    likedBy?: string[];
    parentId: string;
    parentType: ParentType;
    parentCommentId?: string | null;
    topLevelCommentId?: string | null;
}

// Specific comment types
export interface RestaurantReview extends BaseComment {
    parentType: ParentType.RESTAURANT;
    rating: number;  // Required only for top-level restaurant reviews
}

export interface ConfessionComment extends BaseComment {
    parentType: ParentType.CONFESSION;
}

// Union type for all comment types
export type Comment = RestaurantReview | ConfessionComment;

// Type guard functions
export const isRestaurantReview = (comment: Comment): comment is RestaurantReview => {
    return comment.parentType === ParentType.RESTAURANT;
}

export const isConfessionComment = (comment: Comment): comment is ConfessionComment => {
    return comment.parentType === ParentType.CONFESSION;
}
export enum MetricType {
    LIKE = 'like',
    VIEW = 'view',
    UNLIKE = 'unlike'
}
export enum TargetType {
    POST = 'post',
    COMMENT = 'comment',
}
// New: Metric interface
export interface Metric {
    id: string;
    targetId: string;
    targetType: TargetType;
    parentId: string;
    parentType: ParentType;
    userId: string;
    type: MetricType;
    createdAt: Date;
    updatedAt: Date;
}

export interface CreatePostApiResponse {
    postDto: Confession;
    message: string | null;
    businessCode: number | null;
}
'use client'

import { useTheme } from 'next-themes'
import { useEffect } from 'react'

export function ThemeColor() {
  const { resolvedTheme } = useTheme()

  useEffect(() => {
    let meta = document.querySelector('meta[name="theme-color"]')
    if (!meta) {
      meta = document.createElement('meta')
      meta.setAttribute('name', 'theme-color')
      document.head.appendChild(meta)
    }

    const themeColor = resolvedTheme === 'dark' ? '#09090b' : '#ffffff'
    meta.setAttribute('content', themeColor)
  }, [resolvedTheme])

  return null
} 
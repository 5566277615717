import { useEffect, useState } from "react";

export function useBrowserInfo() {
    const [browserInfo, setBrowserInfo] = useState({
      name: '',
      version: '',
      isMobile: false
    });
  
    useEffect(() => {
      const nAgt = navigator.userAgent;
      let browserName = '';
      let fullVersion = '';
      let verOffset;
  
      // Edge
      if ((verOffset = nAgt.indexOf("Edg")) !== -1) {
        browserName = "Edge";
        fullVersion = nAgt.substring(verOffset + 4);
      }
      // Chrome
      else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset + 7);
      }
      // Safari
      else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) !== -1) {
          fullVersion = nAgt.substring(verOffset + 8);
        }
      }
      // Firefox
      else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset + 8);
      }
  
      // Clean up version number
      if ((verOffset = fullVersion.indexOf(";")) !== -1) {
        fullVersion = fullVersion.substring(0, verOffset);
      }
      if ((verOffset = fullVersion.indexOf(" ")) !== -1) {
        fullVersion = fullVersion.substring(0, verOffset);
      }
  
      // Check if mobile
      const isMobile = /iPhone|iPad|iPod|Android/i.test(nAgt);
  
      setBrowserInfo({
        name: browserName,
        version: fullVersion,
        isMobile
      });
    }, []);
  
    return browserInfo;
  }
export default function cloudflareLoader({
    src,
    width,
    quality,
  }: {
    src: string
    width: number
    quality?: number
  }): string {
    const params = [`width=${width}`]
    if (quality) {
      params.push(`quality=${quality}`)
    }
    const paramsString = params.join(',')
    console.log(`/cdn-cgi/image/${paramsString}/${src.startsWith('/') ? src.slice(1) : src}`)
    return `/cdn-cgi/image/${paramsString}/${src.startsWith('/') ? src.slice(1) : src}`
  }
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Pusher from 'pusher-js';
import { pusherClient } from '@/components/notifications/pusher';

export function useNotifications(userId: string) {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!userId || !('SharedWorker' in window)) {
      // Fallback to original implementation
      return;
    }

    try {
      const worker = new SharedWorker(new URL('../components/notifications/pusher-worker.ts', import.meta.url));
      
      // First initialize Pusher
      worker.port.postMessage({ 
        type: 'init',
        config: {
          key: process.env.NEXT_PUBLIC_PUSHER_KEY,
          cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
        }
      });

      // Then subscribe to notifications
      worker.port.postMessage({ type: 'subscribe', userId });

      worker.port.onmessage = (event) => {
        if (event.data.type === 'notification') {
          queryClient.setQueryData(['notifications'], (old: any) => ({
            ...old,
            today: [event.data.data, ...(old?.today || [])]
          }));
        }
      };

      worker.port.start();

      return () => {
        worker.port.close();
      };
    } catch (error) {
      console.error('SharedWorker failed:', error);
      // Fallback to original implementation
    }
  }, [userId, queryClient]);
} 